<template>
  <section class="section-main">
    <div class="section-main__content">
      <inline-svg :src="require('../../assets/icon/svg/error-hint.svg')" />
      <div class="section-main__title">
        <h1 class="fail">系統錯誤</h1>
      </div>
      <p>請重新連線，或稍候再試。</p>
      <p>
        聯絡客服人員：0809-0809-81 / LINE：<a
          href="https://line.me/R/ti/p/@u-powertw?from=page"
          target="_blank"
          >@u-powertw</a
        >
      </p>
    </div>
    <div class="section-main__action">
      <button
        class="button is-fullwidth is-fill is-rounded"
        @click="handleClick()"
        type="button"
      >
        回到首頁
      </button>
    </div>
  </section>
</template>
<script>
export default {
  name: "ErrorMessage",
  methods: {
    handleClick() {
      this.$router.push({
        name: "ConnectorSearch",
      });
    },
  },
};
</script>
